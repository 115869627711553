'use strict'


// import App from './src/App.svelte';

// const app = new App({
//   target: document.body,
//   props: {
//     name: 'world'
//   }
// });

// export default app;


import NavScroll from './scripts/NavScroll'
// import StripeNavigation from './scripts/StripeNavigation'